function initMap() {

    var Map = function () {
        var markers = [];
        function setMap(latLngs) {
            var map = new google.maps.Map(document.getElementById('map'), {
                zoom: 15,
                zoomControl: true,
                disableDefaultUI: true,
                gestureHandling: 'cooperative',
                scrollwheel: false,
                center: new google.maps.LatLng(latLngs[0].Lat, latLngs[0].Lng),
                styles: [{ "elementType": 'geometry', "stylers": [{ "color": '#212121' }] },
                        { "elementType": 'labels.icon', "stylers": [{ "visibility": 'off' }] },
                        { "elementType": 'labels.text.fill', "stylers": [{ "color": '#757575' }] },
                        { "elementType": 'labels.text.stroke', "stylers": [{ "color": '#212121' }] },
                        { "featureType": 'administrative', "elementType": 'geometry', "stylers": [{ "color": '#757575' }] },
                        { "featureType": 'administrative.country', "elementType": 'labels.text.fill', "stylers": [{ "color": '#9e9e9e' }] },
                        { "featureType": 'administrative.land_parcel', "stylers": [{ "visibility": 'off' }] },
                        { "featureType": 'administrative.locality', "elementType": 'labels.text.fill', "stylers": [{ "color": '#bdbdbd' }] },
                        { "featureType": 'poi', "elementType": 'labels.text.fill', "stylers": [{ "color": '#757575' }] },
                        { "featureType": 'poi.park', "elementType": 'geometry', "stylers": [{ "color": '#181818' }] },
                        { "featureType": 'poi.park', "elementType": 'labels.text.fill', "stylers": [{ "color": '#616161' }] },
                        { "featureType": 'poi.park', "elementType": 'labels.text.stroke', "stylers": [{ "color": '#1b1b1b' }] },
                        { "featureType": 'road', "elementType": 'geometry.fill', "stylers": [{ "color": '#2c2c2c' }] },
                        { "featureType": 'road', "elementType": 'labels.text.fill', "stylers": [{ "color": '#8a8a8a' }] },
                        { "featureType": 'road.arterial', "elementType": 'geometry', "stylers": [{ "color": '#373737' }] },
                        { "featureType": 'road.highway', "elementType": 'geometry', "stylers": [{ "color": '#3c3c3c' }] },
                        { "featureType": 'road.highway.controlled_access', "elementType": 'geometry', "stylers": [{ "color": '#4e4e4e' }] },
                        { "featureType": 'road.local', "elementType": 'labels.text.fill', "stylers": [{ "color": '#616161' }] },
                        { "featureType": 'transit', "elementType": 'labels.text.fill', "stylers": [{ "color": '#757575' }] },
                        { "featureType": 'water', "elementType": 'geometry', "stylers": [{ "color": '#000000' }] },
                        { "featureType": 'water', "elementType": 'labels.text.fill', "stylers": [{ "color": '#3d3d3d' }] }
                ]
            });

            var bounds = new google.maps.LatLngBounds();
            var infowindow = new google.maps.InfoWindow({
                content: 'holding...'
            });
            var customMarker = "/common/images/icons/Map_Marker.png";
            markers = [];
            $.each(latLngs, function (i, m) {
                var marker = new google.maps.Marker({
                    position: new google.maps.LatLng(m.Lat, m.Lng),
                    map: map,
                    content: m.Content,
                    icon: customMarker
                });
                google.maps.event.addListener(marker, 'click', function () {
                    infowindow.setContent(marker.content);
                    infowindow.open(map, this);
                });
                markers.push(marker);
                bounds.extend(marker.position);
            });
            if (markers.length > 1) {
                map.fitBounds(bounds);
            }

            google.maps.event.addDomListener(window, "resize", function() {
                var center = map.getCenter();
                google.maps.event.trigger(map, "resize");
                map.setCenter(center); 
            });
        }

        function init() {
            if (window.mapPins && window.mapPins.length > 0) {
                setMap(window.mapPins);
            }
        }

        return init();
    };

    Map();
}